<!-- 党员列表 -->
<template>
  <div class="_page-content">
    <a-row style="height: 100%">
      <a-col :span="4">
        <!-- 左边竖线样式写法 -->
        <div
          style="
            display: flex;
            align-items: center;
            border-left: 4px solid #556bff;
            padding-left: 5px;
          "
        >
          <div class="title">团员列表</div>
        </div>
        <div style="margin-top: 3%; padding-right: 2%">
          <el-input
            placeholder="输入关键字进行过滤"
            prefix-icon="el-icon-search"
            v-model="filterText"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="data"
            @node-click="treeClick"
            :filter-node-method="filterNode"
            ref="tree"
          >
          </el-tree>
        </div>
      </a-col>
      <a-col
        :span="20"
        style="height: 100%; border-left: 1px solid #d8d8d8; padding: 1%"
      >
        <ax-table
          ref="roleTable"
          :columns="columns"
          :show-search="true"
          :toolActions="toolActions"
          :searchForm="searchForm"
          @add="add"
          :sqlParams="sqlParams"
          :dataSourceApi="api.list"
          @bulkDeletion="deleteSecurityPersonnel"
          :dataSourceParams="dataSourceParams"
          @pop-confirm="popConfirm"
          @export="expor"
          @action-column-click="actionColumnClick"
           :scroll="{ y: '55vh', x: '80vw' }"
          :rowSelection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
        >
        </ax-table>
      </a-col>
    </a-row>
    <!-- 党员新增弹窗 -->
     <add-party-member ref="partyMemberInfo"></add-party-member>
  </div>
</template>

<script>
import api from "./api";
import { isEmpty, initGridFormData } from "../../../../../common/tools";
import AddPartyMember from './addPartyMembers.vue';
const duty = [
  { label: "未定位", value: "0" },
  { label: "已定位", value: "1" },
];
const searchForm = [
    {
      label: "关键字",
      type: "",
      model: "keyword",
      options: { placeholder: "网格名称/楼长名" },
      col: { span: 6 },
    },
    {
      label: "支部",
      type: "select",
      model: "locationStasus",
      options: { placeholder: "选择支部", options: duty },
      col: { span: 6 },
    },
    {
      label: "职务",
      type: "select",
      model: "locationStasus",
      options: { placeholder: "选择职务", options: duty },
      col: { span: 6 },
    },
];
export default {
  name: "asset",
  components: {AddPartyMember},
  data() {
    return {
      api,
      treeChoice: "",
      gridName: "",
      level: "",
      selectedRowKeys: [],
      loading: false,
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      dataSourceParams: { gridId: "" },
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            width: 80,
            ellipsis: false,
          },
          {
            title: "性别",
            dataIndex: "gender",
            ellipsis: false,
            width: 50,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: false,
            width: 150,
          },

          {
            title: "籍贯",
            dataIndex: "residencePlace",
            ellipsis: false,
            width: 220,
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            ellipsis: false,
            width: 100,
          },
          {
            title: "所属支部",
            dataIndex: "nation_dictText",
            ellipsis: false,
            width: 80,
          },
          {
            title: "职务",
            dataIndex: "politicalStatus_dictText",
            ellipsis: false,
            width: 80,
          },
          {
            title: "入党时间",
            dataIndex: "updateTime",
            ellipsis: false,
            width: 180,
          }
        ],
        true,
        {
          width: 100,
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#3853db",
                  link: true,
                };
              },
            },

            {
              props: (record) => {
                return {
                  text: "移除",
                  name: "del",
                  type: "#ff444a",
                  link: true,
                  popDisabled: false,
                  title: "确认删除？",
                };
              },
            },
          ],
        }
      ),
      toolActions: [
        { name: "add", text: "新增" },
      ],
      sqlParams: {
        // like: ["userName", "duty"],
      },
      // 左树
      filterText: "",
      data: [],
      treeID: "",
      selectedPeopleList:[]
    };
  },

  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {},
  mounted() {
    this.getgridTree();
  },
  methods: {
    async getTableListData() {
      const obj = {
        pageNo: 1,
        pageSize: 10,
      };
      const res = await api.list(obj);
      this.$refs.roleTable.getDataSource(obj);
      this.selectedPeopleList = res.data.records;
      // console.log(this.selectedPeopleList, "表格已有数据");
    },
    // 导出
    expor() {
      api.export({
        fileName: "楼栋管理.xls",
        params: "",
      });
    },
    // 树过滤
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    // 递归处理树结构
    mapChildren(children) {
      if (!children || children.length === 0) {
        return [];
      }
      return children.map((child) => ({
        label: child.name,
        key: child.id,
        value: child.id,
        level: child.level,
        children: this.mapChildren(child.children), // 递归处理子节点
      }));
    },
    // 获取网格点
    async getgridTree() {
      try {
        const res = await api.gridTree();
        let treeData = res.data.map((res) => {
          return {
            label: res.name,
            key: res.id,
            level: res.level,
            value: res.id,
            children: this.mapChildren(res.children),
          };
        });
        this.data = treeData;
      } catch (error) {
        console.error(error); // 处理错误
      }
    },
    // 树点击
    async treeClick(e) {
      if (e.value) {
        this.dataSourceParams.gridId = e.value;
        this.$refs.roleTable.getDataSource();
        this.treeID = e.value;
      }
      if (e.label) {
        this.gridName = e.label;
      }
      if (e.level) {
        this.level = e.level;
      }
    },

    add() {
      this.$refs.partyMemberInfo.openModal({title:'新增党员',selectedPeopleList: this.selectedPeopleList,})
    },
    //操作区域
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        //单元楼层
        case "unitFloor":
          this.$router.push({
            path: "/views/communityGovernance/housingList/buildingList/unitFloor.vue",
            query: {
              title: "房屋管理",
              recordId: record.id,
            },
          });
          break;
        //楼栋可视化
        case "visual":
          this.$router.push({
            path: "/views/communityGovernance/housingList/buildingList/visual.vue",
            query: {
              title: "楼栋可视化",
              id: record.id,
            },
          });
          break;
        //删除
        case "del":
          break;
      }
    },
    // 表格多选触发回调
    onSelectChange(selectedRowKeys, selectionRows) {
      this.selectedRowKeys = selectedRowKeys;
    },
    //批量删除
    async deleteSecurityPersonnel() {
      if (this.selectedRowKeys.length <= 0) {
        this.$message.warning("请先勾选需要删除的行");
      } else {
        this.$confirm({
          title: "确认删除",
          content: "是否删除选中数据?",
          cancelText: "取消",
          okText: "确定",
          onOk: async () => {
            // 使用箭头函数确保this指向组件实例
            const res = await api.deleteBatch(this.selectedRowKeys);
            if (res.status === 200) {
              this.$message.success("删除成功");
              // 刷新列表数据
              this.$refs.roleTable.getDataSource();
            } else {
              this.$message.error("删除失败");
            }
          },
        });
      }
    },
    // 点击删除操作的api
    async popConfirm({ record }) {
      const res = await api.deleteById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
      } else {
        this.$message.error("删除失败");
      }
      // 刷新列表数据
      this.$refs.roleTable.getDataSource();
    },
    //新增刷新列表数据
    refreshTable() {
      this.$refs.roleTable.getDataSource();
    },
  },
};
</script>
<style lang='less' scoped>
/deep/.el-dialog {
  border-radius: 20px !important;
}
.title {
  font-size: 0.08rem;
  font-weight: 700;
  color: #2d3138;
}
/deep/.is-current {
  color: #556bff !important;
}
/deep/.el-tree-node__content {
  height: 0.16rem;
  font-size: 0.35rem !important;
}
/deep/.el-tree-node__content {
  &:hover {
    background-color: #e8eef4;
  }
}
/deep/.el-tree-node.is-current > .el-tree-node__content {
  background-color: #e8eef4 !important;
}
</style>